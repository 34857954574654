import React, { useState, useEffect } from "react";

export default function Contracts() {
  const [copied1, setCopied1] = useState(false);
  const [copied2, setCopied2] = useState(false);
  const [copied3, setCopied3] = useState(false);
  const [copied4, setCopied4] = useState(false);

  const EGOLDV1_ADDRESS = process.env.REACT_APP_EGOLDV1_ADDR;
  const EGOLDV2_ADDRESS = process.env.REACT_APP_EGOLDV2_ADDR;
  const EGOLD_MINER = process.env.REACT_APP_MINERNFT_ADDR;
  const CBK_ADDRESS = process.env.REACT_APP_CBK_ADDR;

  async function addEgoldTokenFunction(add) {
    if (add == CBK_ADDRESS) {
      try {
        const wasAdded = await window.ethereum.request({
          method: "wallet_watchAsset",
          params: {
            type: "ERC20",
            options: {
              address: add,
              symbol: "EGLD-CBK",
              decimals: 18,
              image: "https://egold.farm/favicon-96x96.png",
            },
          },
        });

        if (wasAdded) {
          console.log("Thanks for your interest!");
        } else {
          console.log("EGOLD has not been added");
        }
      } catch (error) {
        console.log(error);
      }
    } else if (add == EGOLDV2_ADDRESS) {
      try {
        const wasAdded = await window.ethereum.request({
          method: "wallet_watchAsset",
          params: {
            type: "ERC20",
            options: {
              address: add,
              symbol: "EGOLD",
              decimals: 18,
              image: "https://egold.farm/favicon-96x96.png",
            },
          },
        });

        if (wasAdded) {
          console.log("Thanks for your interest!");
        } else {
          console.log("EGOLD has not been added");
        }
      } catch (error) {
        console.log(error);
      }
    } else {
      try {
        const wasAdded = await window.ethereum.request({
          method: "wallet_watchAsset",
          params: {
            type: "ERC20",
            options: {
              address: add,
              symbol: "EGOLD",
              decimals: 18,
              image: "https://egold.farm/favicon-96x96.png",
            },
          },
        });

        if (wasAdded) {
          console.log("Thanks for your interest!");
        } else {
          console.log("EGOLD has not been added");
        }
      } catch (error) {
        console.log(error);
      }
    }
  }
  async function addNFTFunction(add) {
    try {
      const wasAdded = await window.ethereum.request({
        method: "wallet_watchAsset",
        params: {
          type: "ERC20",
          options: {
            address: "0x1F1e263a37945047aF9C1695f2b9d108d956FBD6",
            symbol: "EGMiner",
            decimals: 18,
            image: "https://egold.farm/favicon-96x96.png",
          },
        },
      });
      if (wasAdded) {
        console.log("Thanks for your interest!");
      } else {
        console.log("EGOLD Miner has not been added");
      }
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <div className="scmain main-container w-[100%] text-[0px] relative mx-auto my-0 max-w-[900px]">
      <div className="w-[100%] h-[500px] scontractbg bg-cover bg-no-repeat absolute top-0 left-0 z-[1]" />
        <span className="flex justify-center py-10 font-['Poppins'] text-[40px] font-bold leading-[39.6px] text-[#fff] relative text-left overflow-hidden z-[2]">
        智能合约
        </span>
      <div className="contractflbox h-[140px] flex justify-center gap-[20px] items-end z-[14]">
        <div className="flex flex-col gap-[16px] h-[139px] justify-between items-center z-[34] indiscbox">
          <div className="w-[250px] h-[139px] shrink-0 scbox bg-cover bg-no-repeat relative z-[24]">
            <div className="text-[0px] absolute top-[23px] bottom-[36px] left-[19px] right-[32px] z-[32]">
              <span className="block h-[30px] font-['Poppins'] text-[12px] font-normal opacity-[0.800000011920929] leading-[30px] text-[#000] relative text-left overflow-hidden whitespace-nowrap z-[26] mt-[20px] mr-0 mb-0 ml-0">
                {EGOLDV1_ADDRESS.slice(0, 16) +
                  "......" +
                  EGOLDV1_ADDRESS.slice(-8)}
              </span>
              <span className="flex h-[20px] justify-start items-start font-['Poppins'] text-[20px] font-bold leading-[19.8px] text-[#000] tracking--1px absolute top-0 left-[calc(50%-99.5px)] text-left whitespace-nowrap z-[25]">
                EGOLD (V1)
              </span>
              <div
                className="w-[9.68%] h-[22.29%] metamaskbg bg-[length:100%_100%] bg-no-repeat absolute top-[1.25%] left-[96%] z-[32] cursor-pointer"
                onClick={() => {
                  addEgoldTokenFunction(EGOLDV1_ADDRESS);
                }}
              />
              <button className="w-[80px] h-[27.723px] bg-cover bg-no-repeat rounded-[17px] border-none absolute top-[60px] left-0 shadow-[0_0_40px_0_rgba(178,154,81,0.5)] z-10 pointer" />
              <span
                className="cursor-pointer flex w-[76px] h-[23px] justify-center items-start font-['Poppins'] text-[10px] font-bold leading-[15px] text-[#000] absolute top-[66px] left-[calc(50%-97.5px)] text-center z-[11]"
                onClick={async () => {
                  await navigator.clipboard.writeText(EGOLDV1_ADDRESS);
                  setCopied1(true);
                  setTimeout(function () {
                    setCopied1(false);
                  }, 2000);
                }}
              >
                {copied1 ? "Copied" : "复制链接"}
              </span>
              <button
                className="w-[98px] h-[13px] font-['Poppins'] text-[10px] font-normal opacity-[0.800000011920929] leading-[12px] text-[#000] border-none absolute top-[67px] left-[101px] z-[27] pointer text-right"
                onClick={() => {
                  window.open(
                    "https://bscscan.com/token/" + EGOLDV1_ADDRESS,
                    "_blank"
                  );
                }}
              >
                链上视图
              </button>
            </div>
            <div className="w-[3.4%] h-[5.14%] rightarrowbg bg-[length:100%_100%] bg-no-repeat absolute top-[66%] left-[88.7%] z-[28]" />
          </div>
          <div className="w-[250px] h-[139px] shrink-0 scbox bg-cover bg-no-repeat relative z-[34]">
            <div className="text-[0px] absolute top-[23px] bottom-[36px] left-[19px] right-[32px] z-[32]">
              <span className="block h-[30px] font-['Poppins'] text-[12px] font-normal opacity-[0.800000011920929] leading-[30px] text-[#000] relative text-left overflow-hidden whitespace-nowrap z-[26] mt-[20px] mr-0 mb-0 ml-0">
                {EGOLD_MINER.slice(0, 16) + "......" + EGOLD_MINER.slice(-8)}
              </span>
              <span className="flex h-[20px] justify-start items-start font-['Poppins'] text-[20px] font-bold leading-[19.8px] text-[#000] tracking--1px absolute top-0 left-[calc(50%-99.5px)] text-left whitespace-nowrap z-[25]">
                ANFT - 矿工NFT
              </span>
              <div
                className="w-[9.68%] h-[22.29%] metamaskbg bg-[length:100%_100%] bg-no-repeat absolute top-[1.25%] left-[96%] z-[32] cursor-pointer"
                onClick={() => {
                  addNFTFunction(EGOLD_MINER);
                }}
              />
              <button className="w-[80px] h-[27.723px] bg-cover bg-no-repeat rounded-[17px] border-none absolute top-[60px] left-0 shadow-[0_0_40px_0_rgba(178,154,81,0.5)] z-10 pointer" />
              <span
                className="cursor-pointer flex w-[76px] h-[23px] justify-center items-start font-['Poppins'] text-[10px] font-bold leading-[15px] text-[#000] absolute top-[66px] left-[calc(50%-97.5px)] text-center z-[11]"
                onClick={async () => {
                  await navigator.clipboard.writeText(EGOLD_MINER);
                  setCopied2(true);
                  setTimeout(function () {
                    setCopied2(false);
                  }, 2000);
                }}
              >
                {copied2 ? "Copied" : "复制链接"}
              </span>
              <button
                className="w-[98px] h-[13px] font-['Poppins'] text-[10px] font-normal opacity-[0.800000011920929] leading-[12px] text-[#000] border-none absolute top-[67px] left-[101px] z-[27] pointer text-right"
                onClick={() => {
                  window.open(
                    "https://bscscan.com/token/" + EGOLD_MINER,
                    "_blank"
                  );
                }}
              >
                链上视图
              </button>
            </div>
            <div className="w-[3.4%] h-[5.14%] rightarrowbg bg-[length:100%_100%] bg-no-repeat absolute top-[66%] left-[88.7%] z-[28]" />
          </div>
        </div>
        <div className="flex flex-col gap-[16px] h-[139px] justify-between items-center z-[34] indiscbox">
          <div className="w-[250px] h-[139px] shrink-0 scbox bg-cover bg-no-repeat relative z-[34]">
            <div className="text-[0px] absolute top-[23px] bottom-[36px] left-[19px] right-[32px] z-[32]">
              <span className="block h-[30px] font-['Poppins'] text-[12px] font-normal opacity-[0.800000011920929] leading-[30px] text-[#000] relative text-left overflow-hidden whitespace-nowrap z-[26] mt-[20px] mr-0 mb-0 ml-0">
                {EGOLDV2_ADDRESS.slice(0, 16) +
                  "......" +
                  EGOLDV2_ADDRESS.slice(-8)}
              </span>
              <span className="flex h-[20px] justify-start items-start font-['Poppins'] text-[20px] font-bold leading-[19.8px] text-[#000] tracking--1px absolute top-0 left-[calc(50%-99.5px)] text-left whitespace-nowrap z-[25]">
                EGOLD (V2)
              </span>
              <div
                className="w-[9.68%] h-[22.29%] metamaskbg bg-[length:100%_100%] bg-no-repeat absolute top-[1.25%] left-[96%] z-[32] cursor-pointer"
                onClick={() => {
                  addEgoldTokenFunction(EGOLDV2_ADDRESS);
                }}
              />
              <button className="w-[80px] h-[27.723px] bg-cover bg-no-repeat rounded-[17px] border-none absolute top-[60px] left-0 shadow-[0_0_40px_0_rgba(178,154,81,0.5)] z-10 pointer" />
              <span
                className="cursor-pointer flex w-[76px] h-[23px] justify-center items-start font-['Poppins'] text-[10px] font-bold leading-[15px] text-[#000] absolute top-[66px] left-[calc(50%-97.5px)] text-center z-[11]"
                onClick={async () => {
                  await navigator.clipboard.writeText(EGOLDV2_ADDRESS);
                  setCopied3(true);
                  setTimeout(function () {
                    setCopied3(false);
                  }, 2000);
                }}
              >
                {copied3 ? "Copied" : "复制链接"}
              </span>
              <button
                className="w-[98px] h-[13px] font-['Poppins'] text-[10px] font-normal opacity-[0.800000011920929] leading-[12px] text-[#000] border-none absolute top-[67px] left-[101px] z-[27] pointer text-right"
                onClick={() => {
                  window.open(
                    "https://bscscan.com/token/" + EGOLDV2_ADDRESS,
                    "_blank"
                  );
                }}
              >
                链上视图
              </button>
            </div>
            <div className="w-[3.4%] h-[5.14%] rightarrowbg bg-[length:100%_100%] bg-no-repeat absolute top-[66%] left-[88.7%] z-[28]" />
          </div>
          <div className="w-[250px] h-[139px] shrink-0 scbox bg-cover bg-no-repeat relative z-[24]">
            <div className="text-[0px] absolute top-[23px] bottom-[36px] left-[19px] right-[32px] z-[32]">
              <span className="block h-[30px] font-['Poppins'] text-[12px] font-normal opacity-[0.800000011920929] leading-[30px] text-[#000] relative text-left overflow-hidden whitespace-nowrap z-[26] mt-[20px] mr-0 mb-0 ml-0">
                {CBK_ADDRESS.slice(0, 16) + "......" + CBK_ADDRESS.slice(-8)}
              </span>
              <span className="flex h-[20px] justify-start items-start font-['Poppins'] text-[20px] font-bold leading-[19.8px] text-[#000] tracking--1px absolute top-0 left-[calc(50%-99.5px)] text-left whitespace-nowrap z-[25]">
              CBK - 现金返还
              </span>
              <div
                className="w-[9.68%] h-[22.29%] metamaskbg bg-[length:100%_100%] bg-no-repeat absolute top-[1.25%] left-[96%] z-[32] cursor-pointer"
                onClick={() => {
                  addEgoldTokenFunction(CBK_ADDRESS);
                }}
              />
              <button className="w-[80px] h-[27.723px] bg-cover bg-no-repeat rounded-[17px] border-none absolute top-[60px] left-0 shadow-[0_0_40px_0_rgba(178,154,81,0.5)] z-10 pointer" />
              <span
                className="cursor-pointer flex w-[76px] h-[23px] justify-center items-start font-['Poppins'] text-[10px] font-bold leading-[15px] text-[#000] absolute top-[66px] left-[calc(50%-97.5px)] text-center z-[11]"
                onClick={async () => {
                  await navigator.clipboard.writeText(CBK_ADDRESS);
                  setCopied4(true);
                  setTimeout(function () {
                    setCopied4(false);
                  }, 2000);
                }}
              >
                {copied4 ? "Copied" : "复制链接"}
              </span>
              <button
                className="w-[98px] h-[13px] font-['Poppins'] text-[10px] font-normal opacity-[0.800000011920929] leading-[12px] text-[#000] border-none absolute top-[67px] left-[101px] z-[27] pointer text-right"
                onClick={() => {
                  window.open(
                    "https://bscscan.com/token/" + CBK_ADDRESS,
                    "_blank"
                  );
                }}
              >
                链上视图
              </button>
            </div>
            <div className="w-[3.4%] h-[5.14%] rightarrowbg bg-[length:100%_100%] bg-no-repeat absolute top-[66%] left-[88.7%] z-[28]" />
          </div>
        </div>
      </div>
    </div>
  );
}
