import React, { useEffect, useState } from "react";
import { ResponsivePie } from "@nivo/pie";
import Loader from "../../Loader";
import abiVal from "../data/lpFarm.json";

const TotaleSupply = () => {
  const Web3 = require("web3");
  var FARMING_ABI = require("../data/farmAbi.json");
  const PANCAKE_FACTORY_ABI = require("../data/pancakeFactoryAbi.json");
  const LIQUIDITY_ABI = require("../data/liquidityAbi.json");
  const PANCAKE_FACTORY_ADDRESS = process.env.REACT_APP_PANCAKE_FACTORY_ADDR;
  const FARMING_ADDRESS = process.env.REACT_APP_FARMING_ADDR;
  const EGOLD_ADDRESS = process.env.REACT_APP_EGOLDV1_ADDR;
  const BUSD_ADDRESS = process.env.REACT_APP_BUSD_ADDR;
  const BUSD_EGOLD_FARM_ADDRESS = process.env.REACT_APP_BUSD_EGOLD_FARM;
  const BUSDFARM_ABI = abiVal.farmabi;

  const [data, setData] = useState([]);
  const [mintedtotal, setMintedTotal] = useState("...");
  const [miningStats, setMiningStats] = useState({
    data: "...",
    loading: true,
  });
  const [liquidityEGOLDBlnce, setliquidityEGOLDBlnce] = useState("...");
  const [staketotal, setStakeTotal] = useState("...");
  const [farmyield, setFarmYield] = useState("...");
  const [unclaimedtotal, setUnclaimedTotal] = useState("...");

  const web3 = new Web3(process.env.REACT_APP_RPC);
  const farmInstance = new web3.eth.Contract(FARMING_ABI, FARMING_ADDRESS);
  const pancakeFactorycontractInstance = new web3.eth.Contract(
    PANCAKE_FACTORY_ABI,
    PANCAKE_FACTORY_ADDRESS
  );

  useEffect(() => {
    getSummary();
  }, []);

  const getSummary = async () => {
    const urlMiningStats = "https://egold-miner.herokuapp.com/miningStats";
    const totalstakeurl =
      "https://egold-staking-transactions.herokuapp.com/totalegoldstaked";

    await pancakeFactorycontractInstance.methods
      .getPair(BUSD_ADDRESS, EGOLD_ADDRESS)
      .call({}, function (e, res) {
        const LQInstance = new web3.eth.Contract(LIQUIDITY_ABI, res);
        console.log("RESPONSE LQ ", LQInstance);
        LQInstance.methods.getReserves().call({}, function (e, reserve) {
          console.log("reeserves");
          setliquidityEGOLDBlnce(
            parseFloat(web3.utils.fromWei(reserve[0])).toFixed(3)
          );
        });
      });

    const depositLPTInstance = await new web3.eth.Contract(
      BUSDFARM_ABI,
      BUSD_EGOLD_FARM_ADDRESS
    );

    await depositLPTInstance.methods
      .paidOut()
      .call({}, async function (e, res) {
        setFarmYield(parseFloat(web3.utils.fromWei(res)));
      });

    //fetch total stake
    fetch(totalstakeurl)
      .then(function (response) {
        return response.json();
      })
      .then(function (newData) {
        setStakeTotal(newData.totalStaked);
      })
      .catch(function (error) {
        console.log(error);
      });

    //MiningStats
    fetch(urlMiningStats)
      .then(function (responseStats) {
        return responseStats.json();
      })
      .then(function (newStats) {
        setMiningStats({ data: newStats, loading: false });
      })
      .catch(function (error) {
        console.log("Requestfailed", error);
      });

    await farmInstance.methods.paidOut().call({}, async function (e, res) {
      setMintedTotal(
        parseFloat(parseFloat(web3.utils.fromWei(res)).toFixed(2))
      );
    });

    await farmInstance.methods
      .totalPendingLP()
      .call({}, async function (e, res) {
        setUnclaimedTotal(
          parseFloat(parseFloat(web3.utils.fromWei(res)).toFixed(2))
        );
      });
  };

  const CenteredMetric = ({ centerX, centerY }) => {
    return (
      <text
        x={centerX}
        y={centerY}
        textAnchor="middle"
        dominantBaseline="central"
        fill="black"
        style={{
          fontSize: "15px",
        }}
      >
        {numFormatter(mintedtotal + unclaimedtotal)}
      </text>
    );
  };

  useEffect(() => {
    if (
      mintedtotal !== "..." &&
      unclaimedtotal !== "..." &&
      miningStats.data !== "..."
    ) {
      setData([
        {
          id: "净认领",
          label: "净认领",
          value: noround(mintedtotal - miningStats.data.egoldSupply.burnt, 2),
          color: "#FFD572",
        },
        {
          id: "Unclaimed",
          label: "Unclaimed",
          value: noround(unclaimedtotal, 2),
          color: "#99FFA3",
        },
        {
          id: "已燃烧",
          label: "已燃烧",
          value: noround(miningStats.data.egoldSupply.burnt, 2),
          color: "#FF9364",
        },
      ]);
    }
  }, [mintedtotal, unclaimedtotal, miningStats]);

  //to not round values while showing balance
  function noround(val, x) {
    if (!val) return 0;
    var float = parseFloat(val).toFixed(18);
    var num = float.toString();
    var n = num.slice(0, num.indexOf(".") + (x + 1));
    return n;
  }
  function numFormatter(num) {
    if (num > 999 && num < 1000000) {
      return noround(num / 1000, 2) + " K"; // convert to K for number from > 1000 < 1 million
    } else if (num > 1000000) {
      return noround(num / 1000000, 2) + " M"; // convert to M for number from > 1 million
    } else if (num < 1000) {
      return num; // if value < 1000, nothing to do
    }
  }

  return (
    <>
      {mintedtotal == "..." ||
      unclaimedtotal == "..." ||
      miningStats.data == "..." ? (
        <div className="w-[232px] h-[399px] shrink-0 bg-[#fff] rounded-[30px] relative shadow-[0_4px_100px_0_rgba(0,0,0,0.1)] z-[19] flex justify-center items-center">
          <span className="flex w-[99px] h-[45px] justify-center items-start font-['Poppins'] text-[30px] font-bold leading-[45px] text-[#8d8d8d] tracking--1.5px absolute top-[-50px] left-[65px] text-center whitespace-nowrap z-[23]">
          挖矿
          </span>{" "}
          <Loader />
        </div>
      ) : (
        <div className="w-[232px] h-[399px] shrink-0 bg-[#fff] rounded-[30px] relative shadow-[0_4px_100px_0_rgba(0,0,0,0.1)] z-[19]">
          <span className="flex w-[99px] h-[45px] justify-center items-start font-['Poppins'] text-[30px] font-bold leading-[45px] text-[#8d8d8d] tracking--1.5px absolute top-[-50px] left-[65px] text-center whitespace-nowrap z-[23]">
          挖矿
          </span>
          <div className="w-[90px] h-[44px] font-['Poppins'] text-[20px] font-bold leading-[26px] relative text-center z-20 mt-[32px] mb-0 mx-auto">
            <span className="font-['Poppins'] text-[22px] charttext font-bold leading-[20px] relative text-center">
              {numFormatter(
                noround(miningStats.data.miningRate.miningPower, 2)
              )}
              <br />
            </span>
            <span className="font-['Poppins'] text-[14px] font-normal leading-[20px] text-[#000] relative text-center">
            哈希率
            </span>
          </div>
          <div className="w-[134px] h-[134px] relative z-[45] mt-[15px] mb-0 mx-auto">
            <div style={{ height: "100%" }}>
              <ResponsivePie
                data={data}
                valueFormat=" >-,"
                startAngle={-20}
                endAngle={340}
                sortByValue={false}
                arcLinkLabelsStraightLength={10}
                activeOuterRadiusOffset={8}
                arcLinkLabelsSkipAngle={10}
                arcLinkLabelsTextColor="#333333"
                arcLinkLabelsThickness={2}
                arcLabelsRadiusOffset={0.61}
                arcLinkLabelsColor={{ from: "color" }}
                arcLabelsSkipAngle={10}
                arcLabelsTextColor={{
                  from: "color",
                  modifiers: [["darker", 2]],
                }}
                innerRadius={0.65}
                layers={["arcs", CenteredMetric]}
                colors={(slice) => slice.data.color}
              />
            </div>
          </div>
          <div className="w-[172px] h-[90px] relative z-[22] mt-[13px] mr-0 mb-0 ml-[33px]">
            <span className="flex w-[100px] h-[72px] justify-start items-start font-['Poppins'] text-[10px] font-normal leading-[18px] text-[#000] absolute top-0 left-0 text-left overflow-hidden z-[21]">
            已挖矿
              <br />
              已经认领
              <br />
              已燃烧
              <br />
              净认领
            </span>
            <span className="flex w-[70px] h-[90px] justify-end items-start font-['Poppins'] text-[10px] font-bold leading-[18px] text-[#000] absolute top-0 left-[117px] text-right overflow-hidden z-[22]">
              {parseFloat(
                noround(mintedtotal + unclaimedtotal, 2)
              ).toLocaleString()}
              <br />
              {parseFloat(noround(mintedtotal, 2)).toLocaleString()}
              <br />
              {parseFloat(
                noround(miningStats.data.egoldSupply.burnt, 2)
              ).toLocaleString()}
              <br />
              {parseFloat(
                noround(mintedtotal - miningStats.data.egoldSupply.burnt, 2)
              ).toLocaleString()}
              <br />
            </span>
          </div>
          <a href="https://www.egoldstats.io/" target="_blank">
            <div className="flex w-[190px] h-[35px] justify-center items-center chbtnbg bg-cover bg-center bg-no-repeat rounded-[50px] relative z-[8] mt-[5px] mb-0 m-auto">
              <span className="flex w-[82px] h-[18px] justify-center items-start shrink-0 font-['Poppins'] text-[12px] font-bold leading-[18px] text-[#fff] relative text-center whitespace-nowrap z-[8]">
              挖矿开采统计数据
              </span>
            </div>
          </a>
        </div>
      )}
    </>
  );
};
export default TotaleSupply;
