import React from "react";

export default function KeyFeatures() {
  return (
    <div className="kfmain main-container w-[100%] text-[0px] relative mx-auto my-0 max-w-[900px] kfdisplay">
      <span className="pb-[5px] flex justify-center items-start font-['Poppins'] text-[40px] font-bold leading-[60px] text-[#333] relative text-center whitespace-nowrap mt-0 mb-0 mx-auto">
      主要特征
      </span>
      <span className="flex w-[219px] h-[70px] justify-start items-start font-['Poppins'] text-[13px] font-normal leading-[20px] text-[#919191] tracking--0.65px mx-auto">
      想要了解更多$EGOLD生态系统的好处，请点击下面连接。
        </span>

        <a href="https://egold.gitbook.io/egold/" target="_blank">
          <div className="w-[99px] h-[35px] stacbtn rounded-[17px] relative left-0 z-[3] mx-auto top-[10px]">
            <span className="flex w-[70px] h-[18px] justify-center items-start font-['Poppins'] text-[12px] font-bold leading-[18px] absolute top-[9px] left-[16px] text-center whitespace-nowrap z-[4]">
            更多了解
            </span>
          </div>
        </a>

      <div className="kfflexbx flex justify-between relative z-[11] mt-[80px] mr-0 mb-0 ml-0">
        <a href="https://egold.gitbook.io/egold/introduction/blockchain-agnostic" target="_blank">
        <div className="w-[173px]  font-['Poppins'] text-[30px] font-bold leading-[23px]  text-center z-[5]">
          <div className="w-[74.872px] h-[75px] shrink-0 m-auto mb-[20px] kfblock bg-[length:100%_100%] bg-no-repeat relative z-[6]" />
          <span className="font-['Poppins'] text-[20px] text-[#777777] font-bold mb-4 relative text-center">
          区块链不可知论技术
          </span>
          <br />
          <span className="font-['Poppins'] text-[13px] font-normal leading-[10px] top-[10px] text-[#919191] relative text-center">
          Egold项目操作系列智能合约。该智能合约总是被部署到其他链条，使得项目能够转换到区块链。
          </span>
        </div>
        </a>
        <a href="https://egold.gitbook.io/egold/introduction/best-burn-mechanism" target="_blank">
        <div className="w-[173px] font-['Poppins'] text-[30px] font-bold leading-[23px] text-center z-[8]">
          <div className="w-[75px] h-[75px] shrink-0 m-auto mb-[20px] kfburn bg-cover bg-no-repeat relative overflow-hidden z-[9]" />
          <span className="font-['Poppins'] text-[20px] text-[#777777]  font-bold mb-4 relative text-center">
          最佳燃烧机制
          </span>
          <br />
          <span className="font-['Poppins'] text-[13px] font-normal leading-[10px] top-[10px] text-[#919191] relative text-center">
          我们已经实施矿工燃烧系统，确保开采时所有流通egold能够燃烧到75%。
          </span>
        </div>
        </a>
        <a href="https://egold.gitbook.io/egold/introduction/extreme-deflation" target="_blank">
        <div className="w-[172px] font-['Poppins'] text-[30px] font-bold leading-[23px]   text-center z-[11]">
          <div className="w-[78px] h-[75px] shrink-0 m-auto mb-[20px] kfdeflation bg-cover bg-no-repeat relative overflow-hidden z-[12]" />
          <span className="font-['Poppins'] text-[20px] text-[#777777]  font-bold mb-4 relative text-center">
          极端通货紧缩
          </span>
          <br />
          <span className="font-['Poppins'] text-[13px] font-normal leading-[10px] top-[10px] text-[#919191] relative text-center">
          与其他5%或10%egold燃烧量的数字货币不同，我们设计的代币经济学最大燃烧量可达75%。
          </span>
        </div>
        </a>
        <a href="https://egold.gitbook.io/egold/introduction/eco-friendly-mining" target="_blank">
        <div className="w-[172.308px] font-['Poppins'] text-[30px] font-bold leading-[23px] text-center z-[2]">
          <div className="w-[89px] h-[75px] shrink-0 m-auto mb-[20px] kfmining bg-cover bg-no-repeat relative overflow-hidden z-[3]" />
          <span className="font-['Poppins'] text-[20px] text-[#777777]  font-bold mb-4 relative text-center">
          环保挖矿
          </span>
          <br />
          <span className="font-['Poppins'] text-[13px] font-normal leading-[10px] top-[10px] text-[#919191] relative text-center">
          我们运用ANFT技术移除了传统物理矿工消费的电力需求。
          </span>
        </div>
        </a>
      </div>
    </div>
  );
}
