import React, { useEffect, useState } from "react";
import { ResponsivePie } from "@nivo/pie";
import Loader from "../Loader";

const TotaleSupply = () => {
  const Web3 = require("web3");

  const [data, setData] = useState([]);

  const CenteredMetric = ({ centerX, centerY }) => {
    return (
      <text
        x={centerX}
        y={centerY}
        textAnchor="middle"
        dominantBaseline="central"
        fill="black"
        style={{
          fontSize: "20px",
        }}
      >
        21 M
      </text>
    );
  };

  useEffect(() => {
    setData([
      {
        id: "挖矿",
        label: "挖矿",
        value: noround(20000000, 2),
        color: "#efedf5",
      },
      {
        id: "流动性农场",
        label: "流动性农场",
        value: noround(180000, 2),
        color: "#dadaeb",
      },
      {
        id: "其他分配",
        label: "其他分配",
        value: noround(820000, 2),
        color: "#bcbddc",
      },
    ]);
  }, []);

  //to not round values while showing balance
  function noround(val, x) {
    if (!val) return 0;
    var float = parseFloat(val).toFixed(18);
    var num = float.toString();
    var n = num.slice(0, num.indexOf(".") + (x + 1));
    return n;
  }
  function numFormatter(num) {
    if (num > 999 && num < 1000000) {
      return noround(num / 1000, 2) + " K"; // convert to K for number from > 1000 < 1 million
    } else if (num > 1000000) {
      return noround(num / 1000000, 2) + " M"; // convert to M for number from > 1 million
    } else if (num < 1000) {
      return num; // if value < 1000, nothing to do
    }
  }

  return (
    <>
      <div className="chartbx">
        <div className="chbxhdiv">
          <ResponsivePie
            data={data}
            margin={{ top: 40, right: 80, bottom: 80, left: 80 }}
            valueFormat=" >-,"
            startAngle={-20}
            endAngle={340}
            sortByValue={false}
            arcLinkLabelsStraightLength={10}
            activeOuterRadiusOffset={8}
            arcLinkLabelsSkipAngle={10}
            arcLinkLabelsTextColor="#333333"
            arcLabelsRadiusOffset={0.65}
            arcLinkLabelsThickness={2}
            arcLinkLabelsColor={{ from: "color" }}
            arcLabelsSkipAngle={10}
            arcLabelsTextColor={{
              from: "color",
              modifiers: [["darker", 2]],
            }}
            layers={[
              "arcs",
              "arcLabels",
              "arcLinkLabels",
              "legends",
              CenteredMetric,
            ]}
            colors={(slice) => slice.data.color}
            legends={[
              {
                anchor: "bottom",
                direction: "row",
                justify: false,
                translateX: 0,
                translateY: window.innerWidth < 500 ? 60 : 56,
                itemsSpacing: 0,
                itemWidth: window.innerWidth < 400 ? 95 : 130,
                itemHeight: 18,
                itemTextColor: "#999",
                itemDirection:
                  window.innerWidth < 500 ? "top-to-bottom" : "left-to-right",
                itemOpacity: 1,
                symbolSize: 18,
                symbolShape: "circle",
                effects: [
                  {
                    on: "hover",
                    style: {
                      itemTextColor: "#000",
                    },
                  },
                ],
              },
            ]}
          />
        </div>
        <div className="flex flex-col chtable">
          <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 sm:px-6 lg:px-8">
              <div className="overflow-hidden">
                <table className="min-w-full text-left text-sm font-light">
                  <tbody>
                    <tr className="border-b border-neutral-300">
                      <td className="tableitemx whitespace-nowrap px-6 py-4 font-medium">
                      挖矿
                      </td>
                      <td className="tableitemx whitespace-nowrap px-6 py-4 float-right">
                        {parseFloat(noround(20000000, 2)).toLocaleString()}
                      </td>
                    </tr>
                    <tr className="border-b border-neutral-300">
                      <td className="tableitemx whitespace-nowrap px-6 py-4 font-medium">
                      其他分配
                      </td>
                      <td className="tableitemx whitespace-nowrap px-6 py-4 float-right">
                        {parseFloat(noround(820000, 2)).toLocaleString()}
                      </td>
                    </tr>
                    <tr>
                      <td className="tableitemx whitespace-nowrap px-6 py-4 font-medium">
                      流动性农场
                      </td>
                      <td className="tableitemx whitespace-nowrap px-6 py-4 float-right">
                        {parseFloat(noround(180000, 2)).toLocaleString()}
                      </td>
                    </tr>
                    {/* <tr className="border-b border-neutral-300">
                        <td className="tableitemx whitespace-nowrap px-6 py-4 font-medium">
                          Total Minted EGOLD
                        </td>
                        <td className="tableitemx whitespace-nowrap px-6 py-4 float-right">
                          {parseFloat(
                            noround(
                              mintedtotal -
                                miningStats.data.egoldSupply.burnt +
                                unclaimedtotal +
                                180000 +
                                820000,
                              2
                            )
                          ).toLocaleString()}
                        </td>
                      </tr> */}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default TotaleSupply;
