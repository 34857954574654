import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import { createBrowserRouter, RouterProvider } from "react-router-dom";

import Header from "./components/Header/Header";
import Footer from "./components/Footer/Footer";

import ErrorPage from "./components/ErrorPage";
import Home from "./components/Home";
import UpArrow from "./components/UpArrow";
import Tokenomics from "./components/Tokenomics";
import Calculator from "./components/MiningCalc";
import Disclaimer from "./components/Disclaimer";
import Privacy from "./components/Privacy";
import Terms from "./components/Terms";
import Contact from "./components/Contact";

// To disable log on production
if (process.env.NODE_ENV === "production") {
  console.log = () => {};
  console.error = () => {};
  console.debug = () => {};
}

const router = createBrowserRouter([
  {
    path: "/",
    element: (
      <div className="patternbody">
        <div style={{ flex: "1 1" }}>
          <Header />
          <Home />
        </div>
        <Footer />
      </div>
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: "/tokenomics",
    element: (
      <div className="wrapper">
        <div style={{ flex: "1 1" }}>
          <Header />
          <Tokenomics />
          <UpArrow />
        </div>
        <Footer />
      </div>
    ),
  },
  {
    path: "/calculator",
    element: (
      <div className="wrapper">
        <div style={{ flex: "1 1" }}>
          <Header />
          <Calculator />
        </div>
        <Footer />
      </div>
    ),
  },
  {
    path: "/disclaimer",
    element: (
      <div className="wrapper">
        <div style={{ flex: "1 1" }}>
            <div className="contentbox">
              <Header />
              <Disclaimer />
            </div>
            <UpArrow />
        </div>
        <Footer />
      </div>
    ),
  },
  {
    path: "/privacy",
    element: (
      <div className="wrapper">
        <div style={{ flex: "1 1" }}>
            <div className="contentbox">
              <Header />
              <Privacy />
            </div>
            <UpArrow />
          </div>
        <Footer />
      </div>
    ),
  },
  {
    path: "/terms",
    element: (
      <div className="wrapper">
        <div style={{ flex: "1 1" }}>
            <div className="contentbox">
              <Header />
              <Terms />
            </div>
            <UpArrow />
          </div>
        <Footer />
      </div>
    ),
  },
  {
    path: "/contact",
    element: (
      <div className="wrapper">
        <div style={{ flex: "1 1" }}>
            <div className="contentbox">
              <Header />
              <Contact />
            </div>
            <UpArrow />
          </div>
        <Footer />
      </div>
    ),
  },
]);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

reportWebVitals();
