import React from "react";
import { useNavigate } from "react-router-dom";
import ActiveSupply from "../EGcharts/LandingCharts/DistributedSupply";
import Circulation from "../EGcharts/LandingCharts/DisbursementSupply";
import PriceChange from "../EGcharts/LandingCharts/PriceChange";

export default function Charts() {
  return (
    <div className="main-container w-[100%] relative mx-auto my-0 max-w-[900px]">
      <div className="chartflbox flex h-[399px] justify-between items-center relative z-[19] mt-[50px] mr-0 mb-0 ml-0">
        <PriceChange/>
        <ActiveSupply />
        <Circulation />
      </div>
    </div>
  );
}
