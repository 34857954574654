import React from "react";
import HeadSection from "./Sections/HeadSection";
import StayActive from "./Sections/StayActive";
import EgoldSupply from "./Sections/EgoldSupply";
import TimeLeft from "./Sections/TimeLeft";
import KeyFeatures from "./Sections/KeyFeatures";
import KeyFeatures2 from "./Sections/KeyFeatures2";
import Contracts from "./Sections/Contracts";
import Charts from "./Sections/Charts";
import TechPartners from "./Sections/TechPartners";

export default function Home() {
  return (
    <div className="flex flex-col gap-20">
      <div className="flex flex-col gap-5">
        <HeadSection />
        <StayActive />
      </div>
      <EgoldSupply />
      <TimeLeft />
      <Charts />
      <KeyFeatures />
      <KeyFeatures2 />
      <Contracts />
      <TechPartners/>
    </div>
  );
}
