import React from "react";

export default function Terms() {
  return (
    <div className="container mx-auto  mb-16 pb-5 mt-[100px] md:mt-[50px] lg:mt-10 px-10 lg:pb-[30px] bg-[#dfe7ea] rounded-lg">
      <h1 className="uppercase text-center font-bold pt-8 pb-10 text-base text-[#585858] ">
        条款
      </h1>
      <p className="text-[15px] text-[#585858] leading-8">
        欢迎来到EGOLD，一个在区块链上操作的Web3
        NFT项目。使用我们的服务，表示您同意遵守这些使用条款。如果您不同意这些条款，请不要使用我们的服务。服务使用：您可以使用我们的Web3项目与我们的dAPP进行互动，刷新网站，或者加入我们的社区。您同意将我们的服务仅用于合法目的，不要加入任何违反任何法律或法规的活动。
        知识产权：我们网站上或dAPP上展示的所有内容、商标、标识和其他知识产权
        属于EGOLD或我们许可方的财产。未经事先同意，您不能使用我们的任何知识产权。无投资建议：EGOLD
        并不是金融或者投资顾问，不能提供任何投资建议。我们网站或者dAPP上提供的信息仅供参考，不能理解为投资建议。
        在做任何投资决定前，您应该咨询金融顾问。无保证：
        EGOLD不保证网站上或者dApp上提供的任何信息的准确性、可靠性、完整性或者及时性。
        我们也不保证我们项目的性能或成功。风险提示：使用我们Web3项目涉及风险，包含潜在投资损失。在使用我们的服务前，您应该仔细考虑您的投资项目、经验水平和风险偏好。
        隐私政策：通过使用我们的服务，表示您同意我们的隐私政策。该隐私政策概述我们该如何收集、使用和保护我们的个人信息。赔偿：您同意赔偿和持有EGOLD以及因任何损失、损害或者花费造成我们的职员、董事、员工和附属公司的损害
        ，包括因使用我们的服务或者您违反这些使用条款所引起的律师费。
        管辖法律：这些使用条款将依据U.A.E.法律管辖并解释，任何法律冲突原则都与之不起作用。
        使用条款变更：我们可能偶尔更新这些使用条款。任何重大变更，我们将通过在网站上发布新的使用条款通知您。
        联系我们：如果您有任何条款使用或者服务问题，请联系我们contact@egold.farm。
      </p>
      <br />
    </div>
  );
}
