import React from "react";
import { useNavigate } from "react-router-dom";

export default function HeadSection() {
  let history = useNavigate();
  return (
    <div className="headcontainer mx-auto max-w-screen-xl " id="login">
       <div className="pt-20 sm:pt-12 md:pt-32 hcbg1 relative mx-auto my-0">
        <div className="md:hidden text-center relative z-[1]">
          <a
            href="https://www.egoldproject.com/"
            class=" py-2 px-3 inline text-[#00000073] rounded hover:text-[#856C39] md:p-0 cursor-pointer"
          >
            EN
          </a>{"|"}
          <a
            onClick={() => {
              history("/");
            }}
            class=" py-2 px-3 inline text-black rounded hover:text-[#856C39] md:p-0"
          >
             中国
          </a>{" "}
        </div>
        <div className="relative z-[1] mt-0 mr-0 mb-0 ml-0 pt-12 md:pt-0">
          <span className="flex justify-center items-start font-['Poppins'] headtext sm:text-[70px] font-bold sm:leading-[78px] text-[#000] text-center whitespace-nowrap">
          绝对更优的代币经济学
          </span>
        </div>
        <span className="flex justify-center items-start font-['Poppins'] headsubtext text-[15px] font-normal leading-[19.5px] text-[#000] relative text-center z-[2] mt-[7px] mb-0 max-w-[350px] sm:max-w-[500px] mx-auto">
        Egold 正在减少使用ANFT技术 进行crypto挖矿造成的环境影响，然而使用燃烧机制实施最先进的通货紧缩代币经济学。
        </span>
        <div className="mt-[38px]">
          <div className="flex w-[218px] h-[35px] justify-between items-center relative z-[7]  mx-auto mb-0">
            <a href="https://web2.cn.egoldproject.com">
              <div className="w-[101px] h-[35px] shrink-0 bg-[rgba(217,217,217,0)] rounded-[17px] border-solid border-[1.5px] border-[#000] relative z-[5]">
                <span className="flex w-[49px] h-[18px] justify-center items-start font-['Poppins'] text-[12px] font-normal leading-[18px] text-[#000] absolute top-[8px] left-[26px] text-center whitespace-nowrap z-[8]">
                登录
                </span>
              </div>
            </a>
            <a href="https://web2.cn.egoldproject.com/register">
              <div className="w-[101px] h-[35px] shrink-0 chbtnbg bg-cover bg-no-repeat rounded-[17px] relative z-[7]">
                <span className="flex w-[49px] h-[18px] justify-center items-start font-['Poppins'] text-[12px] font-normal leading-[18px] text-[#fff] absolute top-[8px] left-[26px] text-center whitespace-nowrap z-[8]">
                注册
                </span>
              </div>
            </a>
          </div>
          <a href="https://web3.cn.egoldproject.com/">
            <div className="w-[144.975px] h-[18px] relative z-[13] mt-[21px] mx-auto mb-0">
              <span className="flex w-[99px] h-[18px] justify-center items-start font-['Poppins'] text-[12px] font-normal leading-[18px] text-[#000] absolute top-0 left-[35px] text-center whitespace-nowrap z-10">
              web3登录
              </span>
              <div className="w-[8.82%] h-[65.74%] metamaskbg bg-[length:100%_100%] bg-no-repeat absolute top-[16.67%] left-0 z-[22]" />
              <div className="w-[12px] h-[12px] trustbg bg-cover bg-no-repeat absolute top-[3px] left-[17.455px] overflow-hidden z-[23]" />
              <div className="w-[3.42%] h-[50.58%] rightbg bg-[length:100%_100%] bg-no-repeat absolute top-[21.92%] left-[96.58%] z-[21]" />
            </div>
          </a>
        </div>
      </div>
    </div>
  );
}
