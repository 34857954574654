import React from "react";

export default function Disclaimer() {
  return (
    <div className="container mx-auto  mb-16 pb-5 mt-[100px] md:mt-[50px] lg:mt-10 px-10 lg:pb-[30px] bg-[#dfe7ea] rounded-lg">
      <h1 className="uppercase text-center font-bold pt-8 pb-10 text-base text-[#585858] ">
        免责声明
      </h1>
      <p className="text-[15px] text-[#585858] leading-8">
        EGOLD是一个在区块链上运行的去中心化的NFT项目。EGOLD项目中ANFT矿工挖EGOLD代币。EGOLD代币价值不固定，而且不归团队管理。参加EGOLD项目伴随一些风险。
        在购买EGOLD矿工之前，您应该仔细地考虑自身的目标、经验等级和风险偏好。EGOLD网站、白皮书、ANFT矿工提供的信息和其他任何与项目相关材料，都不应该被理解为财务或者投资建议。您所做的任何财务决定，EGOLD都不会为此而负责。EGOLD不保证该项目提供的任何信息的正确性、完整性或者真实性。EGOLD项目提供的任何信息如有更改，恕不通知。购买EGOLD矿工NFT携带风险，包含您参与份额的潜在损失。您参与项目造成的任何直接、间接、附带或者间接损害，EGOLD都不承担该责任。EGOLD不为第三方服务提供者的任何行为负责，包括支持EGOLD的交易或者钱包。使用前，您应该充分考虑使用第三方服务的相关风险。EGOLD保留随时修改或者更新免责声明的权利，恕不另行通知。通过参加EGOLD项目，您承认您已经阅读和理解该免责声明以及同意该条款和条件。
      </p>
      <br />
    </div>
  );
}
