import React, { useEffect, useState } from "react";
import { ResponsiveBar } from "@nivo/bar";
import Loader from "../Loader";

const ProductionCycles = () => {
  const [data, setData] = useState([]);
  const [showAllRows, setShowAllRows] = useState(false);

  const handleShowAll = () => {
    setShowAllRows(true);
  };
  const handleCloseAll = () => {
    setShowAllRows(false);
  };

  useEffect(() => {
    setData([
      {
        year: "1-4",
        tokens: 10000000,
        burgerColor: "hsl(143, 70%, 50%)",
      },
      {
        year: "5-8",
        tokens: 5000000,
        burgerColor: "hsl(143, 70%, 50%)",
      },
      {
        year: "9-12",
        tokens: 2500000,
        burgerColor: "hsl(143, 70%, 50%)",
      },
      {
        year: "13-16",
        tokens: 1250000,
        burgerColor: "hsl(143, 70%, 50%)",
      },
      {
        year: "17-20",
        tokens: 625000,
        burgerColor: "hsl(143, 70%, 50%)",
      },
      {
        year: "21-24",
        tokens: 312500,
        burgerColor: "hsl(143, 70%, 50%)",
      },
      {
        year: "25-28",
        tokens: 156250,
        burgerColor: "hsl(143, 70%, 50%)",
      },
      {
        year: "29-40",
        tokens: 136718.25,
        burgerColor: "hsl(143, 70%, 50%)",
      },
      {
        year: "41-80",
        tokens: 19531.26,
        burgerColor: "hsl(143, 70%, 50%)",
      },
    ]);
  }, []);

  //to not round values while showing balance
  function noround(val, x) {
    if (!val) return 0;
    var float = parseFloat(val).toFixed(18);
    var num = float.toString();
    var n = num.slice(0, num.indexOf(".") + (x + 1));
    return n;
  }
  const chartTheme = {
    axis: {
      ticks: {
        text: {
          fontSize: 8, // Adjust the font size as per your requirement
        },
      },
    },
  };

  return (
    <>
      {data.length == 0 ? (
        <div className="cocont container mx-auto mt-4 mb-16 p-5 sm:mt-2 lg:mt-10 px-10 lg:p-[30px] bg-[#f8f4e9] rounded-lg">
          {" "}
          <Loader />
        </div>
      ) : (
        <div className="chartbx">
          <div className="chbxhdiv">
            <ResponsiveBar
              data={data}
              theme={window.innerWidth > 500 ? "" : chartTheme}
              keys={["tokens"]}
              indexBy="year"
              margin={
                window.innerWidth > 2500
                  ? { top: 50, right: 40, bottom: 50, left: 90 }
                  : window.innerWidth > 2000
                  ? { top: 50, right: 50, bottom: 50, left: 90 }
                  : window.innerWidth > 1200
                  ? { top: 50, right: 30, bottom: 50, left: 90 }
                  : window.innerWidth > 500
                  ? { top: 50, right: 20, bottom: 50, left: 90 }
                  : { top: 50, right: 20, bottom: 50, left: 50 }
              }
              padding={0.3}
              valueScale={{ type: "linear" }}
              indexScale={{ type: "band", round: true }}
              colors={{ scheme: "nivo" }}
              borderColor={{
                from: "color",
                modifiers: [["darker", 1.6]],
              }}
              axisTop={null}
              axisRight={null}
              axisBottom={{
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                legend: "年",
                legendPosition: "middle",
                legendOffset: 39,
              }}
              axisLeft={{
                tickSize: 1,
                tickPadding: 0,
                tickRotation: 0,
                legend: "已生产代币",
                legendPosition: "middle",
                legendOffset: window.innerWidth > 500 ? -65 : -40,
              }}
              enableLabel={window.innerWidth > 500 ? true : false}
              labelSkipWidth={12}
              labelSkipHeight={12}
              labelTextColor={{
                from: "color",
                modifiers: [["darker", 1.6]],
              }}
              tooltip={({ id, value, indexValue, color }) => (
                <div
                  style={{
                    padding: 12,
                    color,
                    background: "#222222",
                    textAlign: "center",
                  }}
                >
                  <span>Year {indexValue} </span>
                  <br />
                  <strong>Produces : {value}</strong>
                </div>
              )}
              role="application"
            />
          </div>
          <br className="mobnone" />
          <div className="flex flex-col">
            <div className="my-2 overflow-x-auto sm:mx-6 lg:mx-8 prodtable text-center">
              <div
                className="py-2 align-middle inline-block"
                style={{ width: "100%" }}
              >
                <div className="overflow-auto border-b border-gray-200 sm:rounded-lg">
                  <table
                    className="divide-y divide-gray-200"
                    style={{ width: "100%" }}
                  >
                    <thead className="bg-gray-50">
                      <tr>
                        <th
                          scope="col"
                          className="px-6 py-3 text-xs font-medium tracking-wider text-center text-gray-500 uppercase"
                        >
                          从年开始
                        </th>
                        <th
                          scope="col"
                          className="px-6 py-3 text-xs font-medium tracking-wider text-center text-gray-500 uppercase"
                        >
                          代币
                        </th>
                        <th
                          scope="col"
                          className="px-6 py-3 text-xs font-medium tracking-wider text-center text-gray-500 uppercase"
                        >
                          每天
                        </th>
                      </tr>
                    </thead>
                    <tbody className="bg-white divide-y divide-gray-200 text-[14px]">
                      <tr>
                        <td className="px-6 py-4 whitespace-nowrap">
                          <strong>1 - 4</strong>
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          10,000,000
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          6849.315068
                        </td>
                      </tr>
                      <tr>
                        <td className="px-6 py-4 whitespace-nowrap">
                          <strong>5 - 8</strong>
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          5,000,000
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          3424.657534
                        </td>
                      </tr>
                      <tr>
                        <td className="px-6 py-4 whitespace-nowrap">
                          <strong>9 - 12</strong>
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          2,500,000
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          1712.328767
                        </td>
                      </tr>
                      <tr>
                        <td className="px-6 py-4 whitespace-nowrap">
                          <strong>13 - 16</strong>
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          1,250,000
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          856.1643836
                        </td>
                      </tr>
                      <tr>
                        <td className="px-6 py-4 whitespace-nowrap">
                          <strong>17 - 20</strong>
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">625,000</td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          428.0821918
                        </td>
                      </tr>
                      <tr>
                        <td className="px-6 py-4 whitespace-nowrap">
                          <strong>21 - 24</strong>
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">312,500</td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          214.0410959
                        </td>
                      </tr>
                      <tr>
                        <td className="px-6 py-4 whitespace-nowrap">
                          <strong>25 - 28</strong>
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">156,250</td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          107.0205479
                        </td>
                      </tr>
                      <tr>
                        <td className="px-6 py-4 whitespace-nowrap">
                          <strong>29 - 32</strong>
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">78,125</td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          53.51027397
                        </td>
                      </tr>

                      {showAllRows && (
                        <>
                          <tr>
                            <td className="px-6 py-4 whitespace-nowrap">
                              <strong>33 - 36</strong>
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap">
                              39,062
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap">
                              26.75513698
                            </td>
                          </tr>
                          <tr>
                            <td className="px-6 py-4 whitespace-nowrap">
                              <strong>37 - 40</strong>
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap">
                              19,531
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap">
                              13.37756849
                            </td>
                          </tr>
                          <tr>
                            <td className="px-6 py-4 whitespace-nowrap">
                              <strong>41 - 44</strong>
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap">
                              9,766
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap">
                              6.688784244
                            </td>
                          </tr>
                          <tr>
                            <td className="px-6 py-4 whitespace-nowrap">
                              <strong>45 - 48</strong>
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap">
                              4,882
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap">
                              3.344392122
                            </td>
                          </tr>
                          <tr>
                            <td className="px-6 py-4 whitespace-nowrap">
                              <strong>49 - 52</strong>
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap">
                              2,441
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap">
                              1.672196061
                            </td>
                          </tr>
                          <tr>
                            <td className="px-6 py-4 whitespace-nowrap">
                              <strong>53 - 56</strong>
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap">
                              1,221
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap">
                              0.8360980306
                            </td>
                          </tr>
                          <tr>
                            <td className="px-6 py-4 whitespace-nowrap">
                              <strong>57 - 60</strong>
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap">
                              610.35
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap">
                              0.418049015
                            </td>
                          </tr>
                          <tr>
                            <td className="px-6 py-4 whitespace-nowrap">
                              <strong>61 - 64</strong>
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap">
                              305.18
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap">
                              0.209024508
                            </td>
                          </tr>
                          <tr>
                            <td className="px-6 py-4 whitespace-nowrap">
                              <strong>65 - 68</strong>
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap">
                              152.59
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap">
                              0.104512254
                            </td>
                          </tr>
                          <tr>
                            <td className="px-6 py-4 whitespace-nowrap">
                              <strong>69 - 72</strong>
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap">
                              76.29
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap">
                              0.052256127
                            </td>
                          </tr>
                          <tr>
                            <td className="px-6 py-4 whitespace-nowrap">
                              <strong>73 - 76</strong>
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap">
                              38.15
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap">
                              0.026128063
                            </td>
                          </tr>
                          <tr>
                            <td className="px-6 py-4 whitespace-nowrap">
                              <strong>77 - 80</strong>
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap">
                              38.15
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap">
                              0.026128063
                            </td>
                          </tr>
                        </>
                      )}
                    </tbody>
                  </table>
                  {showAllRows ? (
                    <button
                      className="mx-auto w-full bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-b"
                      onClick={handleCloseAll}
                    >
                      隐藏
                    </button>
                  ) : (
                    <button
                      className="mx-auto w-full bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-b"
                      onClick={handleShowAll}
                    >
                      展示全部
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ProductionCycles;
