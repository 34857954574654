import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

export default function Footer() {
  let history = useNavigate();
  return (
    <div className="mt-[50px]">
      <div className="footerdivbg1">
        <div className="footerbg1fbx main-container w-[100%] text-[0px] relative mx-auto my-0 max-w-[1200px]">
          <div className="fffbox flex justify-between relative z-[7] mr-0 mb-0 ">
            <div className="footlog flex flex-col gap-[20px]">
              <div
                className="h-[50px] egoldbg bg-contain bg-no-repeat overflow-hidden z-[2]"
                onClick={() => {
                  history("/");
                }}
              />
              <span className="fmenu flex w-[226px] h-[34px] justify-start items-start font-['Poppins'] text-[13px] font-light leading-[16.9px] text-[#8a8a8a] top-[92px] text-left overflow-hidden z-[3]">
              Egold项目因其独特性和最佳代币经济学被认可。
              </span>
            </div>
            <div className="fmenu h-[307px] font-['Poppins'] text-[15px] font-light leading-[30px] text-left whitespace-nowrap z-[5]">
              <span className="font-['Poppins'] text-[20px] font-bold leading-[40px] text-[#000] relative text-left">
                EGOLD
                <br />
              </span>
              <span
                className="font-['Poppins'] text-[15px] font-light leading-[30px] text-[#000] relative text-left cursor-pointer"
                onClick={() => {
                  history("/tokenomics");
                }}
              >
                更好的代币经济
                <br />
              </span>
              <span className="font-['Poppins'] text-[15px] font-light leading-[30px] text-[#626262] relative text-left">
              环保
                <br />
                <a href="https://www.egoldstats.io/" target="_blank">
                挖矿统计数据
                </a>
                <br />
                <a
                  className="cursor-pointer"
                  onClick={() => {
                    history("/calculator");
                  }}
                >
                  挖矿计算器
                </a>
                <br />
                <a
                  className="cursor-pointer"
                  onClick={() => {
                    history("/terms");
                  }}
                >
                  条款
                </a>
                <br />
                <a
                  className="cursor-pointer"
                  onClick={() => {
                    history("/disclaimer");
                  }}
                >
                  免责声明
                </a>
                <br />
                <a
                  className="cursor-pointer"
                  onClick={() => {
                    history("/privacy");
                  }}
                >
                  隐私
                </a>
                <br />
                <a
                  className="cursor-pointer"
                  onClick={() => {
                    history("/contact");
                  }}
                >
                  联系方式
                </a>
                <br />
              </span>
            </div>
            <div className="fmenu h-[290px] font-['Poppins'] text-[15px] font-light leading-[30px] text-left whitespace-nowrap z-[6]">
              <span className="font-['Poppins'] text-[20px] font-bold leading-[40px] text-[#000] relative text-left">
              更多了解
                <br />
              </span>
              <span className="font-['Poppins'] text-[15px] font-light leading-[30px] text-[#626262] relative text-left">
                <a href="https://egold.gitbook.io/egold/" target="_blank">
                概述
                </a>
                <br />
                <a
                  href="https://egold.gitbook.io/egold/egold-tokenomics"
                  target="_blank"
                >
                  Egold代币经济学
                </a>
                <br />
                <a
                  href="https://egold.gitbook.io/egold/how-to-use/how-to-register-on-egold"
                  target="_blank"
                >
                  怎样使用
                </a>
                <br />
                <a
                  href="https://egold.gitbook.io/egold/egold-mining-guide/introduction"
                  target="_blank"
                >
                  Egold 挖矿指南
                </a>
                <br />
                <a
                  href="https://egold.gitbook.io/egold/list-of-miners"
                  target="_blank"
                >
                  矿工清单
                </a>
                <br />
                <a
                  href="https://egold.gitbook.io/egold/egold-staking"
                  target="_blank"
                >
                  Egold 权益质押
                </a>
                <br />
                <a
                  href="https://egold.gitbook.io/egold/project-roadmap"
                  target="_blank"
                >
                  项目路线图
                </a>
                <br />
              </span>
            </div>
            <div className="fmenu h-[157px] font-['Poppins'] text-[15px] font-light leading-[30px] text-left whitespace-nowrap z-[7]">
              <span className="font-['Poppins'] text-[20px] font-bold leading-[40px] text-[#000] relative text-left">
              联系方式
                <br />
              </span>
              <span className="font-['Poppins'] text-[15px] font-light leading-[30px] text-[#626262] relative text-left">
                <a
                  href={
                    "https://bscscan.com/token/" +
                    process.env.REACT_APP_EGOLDV1_ADDR
                  }
                  target="_blank"
                >
                  Egold V1
                </a>
                <br />
                <a
                  href={
                    "https://bscscan.com/token/" +
                    process.env.REACT_APP_EGOLDV2_ADDR
                  }
                  target="_blank"
                >
                  Egold V2
                </a>
                <br />
                <a
                  href={
                    "https://bscscan.com/token/" +
                    process.env.REACT_APP_CBK_ADDR
                  }
                  target="_blank"
                >
                  CBK - 现金返还
                </a>
                <br />
                <a
                  href={
                    "https://bscscan.com/token/" +
                    process.env.REACT_APP_MINERNFT_ADDR
                  }
                  target="_blank"
                >
                  ANFT -  矿工NFT
                </a>
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className="footerdivbg2">
        <div className="main-container w-[100%] text-[0px] relative mx-auto my-0 max-w-[1200px] py-[25px]">
          <div className="footerbg2fbx h-[45px] relative z-10 mr-0 mb-0 flex justify-between">
            <span className="flex h-[19px] justify-start items-start font-['Poppins'] text-[15px] font-light leading-[19px] text-[#8a8a8a] text-left whitespace-nowrap z-[4]">
              © 2024 The Egold Project. All rights reserved.
            </span>
            <div className="flex gap-[10px] h-full justify-between items-center z-10">
              <div className="w-[45.131px] h-[45px] xbg bg-[length:100%_100%] bg-no-repeat relative z-[9]" />

              <a
                href="https://t.me/egold_farm"
                class="telegram"
                target="_blank"
              >
              <div className="w-[45px] h-[45px] shrink-0 tgbg bg-[length:100%_100%] bg-no-repeat relative z-10" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
