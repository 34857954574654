import React from "react";

export default function Privacy() {
  return (
    <div className="container mx-auto  mb-16 pb-5 mt-[100px] md:mt-[50px] lg:mt-10 px-10 lg:pb-[30px] bg-[#dfe7ea] rounded-lg">
      <h1 className="uppercase text-center font-bold pt-8 pb-10 text-base text-[#585858] ">
        隐私
      </h1>
      <p className="text-[15px] text-[#585858] leading-8">
        允许EGOLD保护您的隐私和个人信息。该隐私政策概括我们如何收集、使用和保护个人信息。信息收集：当您在我们Web3项目互动时，包括但不限于刷新网页，App上互动或者参加我们的活动，我们进行个人信息收集。该信息可能包含您的姓名、邮箱地址、钱包地址、IP地址和其他为我们服务提供的必要数据。信息使用：我们使用您的个人信息为您提供我们的服务，提升我们产品和服务质量，以及与您交流我们项目更新、新闻和
        广告宣传情况。我们也会使用您的信息进行统计或者调查。信息分享：我们不会将您的信息出售或者租赁给第三方。然而，我们可能将您的信息分享给第三方服务提供者，他们帮助我们运营业务或者代表我们提供服务。我们也可能披露您的个人信息用于遵守法律义务或者保护我们的权利或者财产。
        保密措施：我们采取合适的技术和组织措施保护您的个人信息免受未经授权的访问、披露、更改或者销毁。我们使用行业标准的安全协议，包括加密，保护您的信息。
        数据保留：我们保留您的个人信息为我们提供服务或者法律需要，以备不时之需。我们也可能保留您的个人信息用于搜索或者数据统计。Web3
        隐私：
        我们的Web3项目在区块链上运行，它是一个去中心化和不可变的账本项目。
        通过使用我们的Web3项目，您同意您的信息可能在区块链上公开可见。
        然而，我们采取合理的措施保护您在区块链上的隐私，包括使用假名钱包地址和其他增强隐私技术。
        隐私政策变化：我们可能偶尔更新该隐私政策。任何重大变更，我们将会在网站上发布新的隐私政策来通知您。
        联系我们：如果您有任何隐私政策或者数据做法问题，请联系我们contact@egold.farm。通过使用Web3项目，您同意我们的隐私政策和数据做法。
      </p>
      <br />
    </div>
  );
}
