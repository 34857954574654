import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import headerlogo from "../../images/headerlogo.png";

export default function Header() {
  let history = useNavigate();
  const [navbarOpen, setNavbarOpen] = React.useState(false);
  const [dailystate, setDailystate] = useState({ data: null, loading: true });
  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    const url24 =
      "https://egold-marketdata.herokuapp.com/v1/summary/getLatestTrades/1/";
    //fetch 24 hour market data
    fetch(url24)
      .then(function (response) {
        return response.json();
      })
      .then(function (newData) {
        setDailystate({ data: newData, loading: false });
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);

  return (
    <nav
      className={`scrollDiv ${
        scrolled
          ? "bg-white fixed md:static w-full z-[1000] top-0 start-0 pt-0 md:pt-5"
          : "fixed md:static w-full z-[1000] top-0 start-0 pt-0 md:pt-5"
      }`}
    >
      <div class="max-w-screen-xl flex flex-wrap items-center justify-between mx-auto p-4">
        <a
          class="flex items-center space-x-3 rtl:space-x-reverse cursor-pointer"
          onClick={() => {
            history("/");
          }}
        >
          <img src={headerlogo} class="h-8" alt="Egold Logo" />
        </a>
        <div class="flex md:order-2 space-x-3 md:space-x-0 md:gap-[30px]">
          <div
            class={
              navbarOpen
                ? "items-center justify-between block w-full md:flex md:w-auto"
                : "items-center justify-between hidden w-full md:flex md:w-auto"
            }
            id="navbar-sticky"
          >
            <ul class="headerul font-medium hidden md:flex flex-col p-4 md:p-0 mt-4 text-[13px] gap-[30px] border border-gray-100 rounded-lg bg-transparent z-[100] md:flex-row md:space-x-8 rtl:space-x-reverse md:mt-0 md:border-0 dark:border-gray-700">
            <li>
                <a
                  href="https://www.egoldproject.com/"
                  class=" py-2 px-3 inline text-[#00000073] rounded hover:text-[#856C39] md:p-0 cursor-pointer"
                >
                  EN |
                </a>{"  "}
                <a
                  onClick={() => {
                    history("/");
                  }}
                  class=" py-2 px-3 inline text-black rounded hover:text-[#856C39] md:p-0"
                >
                   中国
                </a>{" "}
              </li>
              <li>
                <a
                  onClick={() => {
                    history("/contact");
                  }}
                  class="block py-2 px-3 text-black rounded hover:text-[#856C39] md:p-0 cursor-pointer"
                >
                  联系方式
                </a>
              </li>
              <li>
                <a
                  href="/#partners"
                  class="block py-2 px-3 text-black rounded hover:text-[#856C39] md:p-0"
                >
                  合作伙伴
                </a>
              </li>
              <li>
                <a
                  href="https://egold.gitbook.io/egold/"
                  target="_blank"
                  class="block py-2 px-3 text-black rounded hover:text-[#856C39] md:p-0 cursor-pointer"
                >
                  白皮书
                </a>
              </li>
            </ul>
          </div>
          <div className="flex justify-center w-[112px] h-[35px] shrink-0 hrpricebg bg-contain bg-no-repeat rounded-[17px] relative z-[7]">
            <span className="flex justify-center items-start font-['Poppins'] text-[12px] font-semibold leading-[18px] text-[#fff] absolute top-[8px] text-center whitespace-nowrap z-[8]">
            价格:{" "}
              {dailystate.loading == true
                ? "..."
                : parseFloat(dailystate.data[0].TAG_USD).toFixed(3)}
            </span>
          </div>
          {/* <button type="button" class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-2 text-center ">Price: 0.498</button> */}
          <button
            data-collapse-toggle="navbar-sticky"
            type="button"
            class="inline-flex items-center p-2 w-10 h-10 justify-center text-sm text-gray-500 rounded-lg md:hidden focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600"
            aria-controls="navbar-sticky"
            aria-expanded="false"
            onClick={() => setNavbarOpen(!navbarOpen)}
          >
            <svg
              class="w-5 h-5"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 17 14"
            >
              <path
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M1 1h15M1 7h15M1 13h15"
              />
            </svg>
          </button>
        </div>
      </div>
      <div class="flex md:order-2 md:hidden space-x-3 md:space-x-0 md:gap-[30px]">
        <div
          class={
            navbarOpen
              ? "items-center justify-between block w-full md:flex md:w-auto mx-[10px]"
              : "items-center justify-between hidden w-full md:flex md:w-auto"
          }
          id="navbar-sticky"
        >
          <ul class="headerul font-medium flex flex-col p-4 md:p-0 mt-4 text-[13px] gap-[30px] border border-gray-100 rounded-lg bg-gray-50 md:flex-row md:space-x-8 rtl:space-x-reverse md:mt-0 md:border-0 md:bg-white dark:border-gray-700">
            <li>
              <a
                onClick={() => {
                  history("/contact");
                }}
                class="block py-2 px-3 text-black rounded hover:text-[#856C39] md:p-0"
              >
                联系方式
              </a>
            </li>
            <li>
              <a
                href="/#partners"
                class="block py-2 px-3 text-black rounded hover:text-[#856C39] md:p-0"
              >
                合作伙伴
              </a>
            </li>
            <li>
              <a
                href="https://egold.gitbook.io/egold/"
                target="_blank"
                class="block py-2 px-3 text-black rounded hover:text-[#856C39] md:p-0 cursor-pointer"
              >
                白皮书
              </a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
}
