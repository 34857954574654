import React, { useEffect, useState } from "react";
import { ResponsivePie } from "@nivo/pie";
import Loader from "../Loader";

const TotaleSupply = () => {
  const Web3 = require("web3");
  var FARMING_ABI = require("./data/farmAbi.json");
  var TOKEN_ABI = require("./data/tokenAbi.json");
  const FARMING_ADDRESS = process.env.REACT_APP_FARMING_ADDR;
  const EGOLD_ADDRESS = process.env.REACT_APP_EGOLDV1_ADDR;

  const [data, setData] = useState([]);
  const [mintedtotal, setMintedTotal] = useState("...");
  const [unclaimedtotal, setUnclaimedTotal] = useState("...");
  const [totalburnt, setTotalBurnt] = useState("...");
  const [currentSupply, setcurrentSupply] = useState("...");

  const web3 = new Web3(process.env.REACT_APP_RPC);
  const farmInstance = new web3.eth.Contract(FARMING_ABI, FARMING_ADDRESS);
  const egtokenInstance = new web3.eth.Contract(TOKEN_ABI, EGOLD_ADDRESS);

  useEffect(() => {
    getSummary();
  }, []);

  const getSummary = async () => {
    const urlMiningStats = "https://egold-miner.herokuapp.com/miningStats";

    //getburnt
    fetch(urlMiningStats)
      .then(function (responseStats) {
        return responseStats.json();
      })
      .then(function (newStats) {
        setTotalBurnt(newStats.egoldSupply.burnt);
      })
      .catch(function (error) {
        console.log("Requestfailed", error);
      });

    await farmInstance.methods.paidOut().call({}, async function (e, res) {
      setMintedTotal(
        parseFloat(parseFloat(web3.utils.fromWei(res)).toFixed(2))
      );
    });
    await farmInstance.methods
      .totalPendingLP()
      .call({}, async function (e, res) {
        setUnclaimedTotal(
          parseFloat(parseFloat(web3.utils.fromWei(res)).toFixed(2))
        );
      });
    await egtokenInstance.methods
      .totalSupply()
      .call({}, async function (e, res) {
        setcurrentSupply(
          parseFloat(parseFloat(web3.utils.fromWei(res)).toFixed(2))
        );
      });
  };

  const CenteredMetric = ({ centerX, centerY }) => {
    return (
      <text
        x={centerX}
        y={centerY}
        textAnchor="middle"
        dominantBaseline="central"
        fill="black"
        style={{
          fontSize: "30px",
        }}
      >
        21 M
      </text>
    );
  };

  useEffect(() => {
    if (currentSupply !== "..." && totalburnt !== "...") {
      setData([
        {
          id: "当前供应量",
          label: "当前供应量",
          value: noround(currentSupply, 0),
          color: "#9aee7a",
        },
        {
          id: "已燃烧",
          label: "已燃烧",
          value: noround(totalburnt, 0),
          color: "#fc6b51",
        },
      ]);
    }
  }, [currentSupply, totalburnt]);

  //to not round values while showing balance
  function noround(val, x) {
    if (!val) return 0;
    var float = parseFloat(val).toFixed(18);
    var num = float.toString();
    var n = num.slice(0, num.indexOf(".") + (x + 1));
    return n;
  }

  return (
    <>
      {currentSupply == "..." || totalburnt == "..." ? (
        <div className="cocont container mx-auto mt-4 mb-16 p-5 sm:mt-2 lg:mt-10 px-10 lg:p-[30px] bg-[#f8f4e9] rounded-lg">
          {" "}
          <Loader />
        </div>
      ) : (
        <div className="chartbx">
          <div className="chbxhdiv">
            <ResponsivePie
              data={data}
              margin={{ top: 40, right: 80, bottom: 80, left: 80 }}
              valueFormat=" >-,"
              startAngle={5}
              endAngle={380}
              sortByValue={false}
              activeOuterRadiusOffset={window.innerWidth < 500 ? 0 : 8}
              arcLinkLabelsSkipAngle={1}
              arcLinkLabelsStraightLength={window.innerWidth < 500 ? 5 : 10}
              arcLinkLabelsTextColor="#333333"
              arcLinkLabelsThickness={2}
              arcLinkLabelsColor={{ from: "color" }}
              arcLabelsSkipAngle={10}
              arcLabelsTextColor={{
                from: "color",
                modifiers: [["darker", 2]],
              }}
              innerRadius={0.65}
              enableArcLabels={false}
              arcLinkLabel={(d) => `${d.id} (${d.formattedValue})`}
              activeInnerRadiusOffset={8}
              layers={[
                "arcs",
                "arcLabels",
                "arcLinkLabels",
                "legends",
                CenteredMetric,
              ]}
              colors={(slice) => slice.data.color}
              legends={[
                {
                  anchor: "bottom",
                  direction: "row",
                  justify: false,
                  translateX: 0,
                  translateY: window.innerWidth < 500 ? 60 : 56,
                  itemsSpacing: 0,
                  itemWidth: 120,
                  itemHeight: 18,
                  itemTextColor: "#999",
                  itemDirection:
                    window.innerWidth < 500 ? "top-to-bottom" : "left-to-right",
                  itemOpacity: 1,
                  symbolSize: 18,
                  symbolShape: "circle",
                  effects: [
                    {
                      on: "hover",
                      style: {
                        itemTextColor: "#000",
                      },
                    },
                  ],
                },
              ]}
            />
          </div>
          <div class="flex flex-col chtable">
            <div class="overflow-x-auto sm:-mx-6 lg:-mx-8">
              <div class="inline-block min-w-full py-2 sm:px-6 lg:px-8">
                <div class="overflow-hidden">
                  <table class="min-w-full text-left text-sm font-light">
                    <tbody>
                      <tr class="border-b border-neutral-300">
                        <td className="tableitemx whitespace-nowrap px-6 py-4 font-medium">
                        最大供应量
                        </td>
                        <td className="tableitemx whitespace-nowrap px-6 py-4 float-right">
                          21,000,000.00
                        </td>
                      </tr>
                      <tr class="border-b border-neutral-300">
                        <td className="tableitemx whitespace-nowrap px-6 py-4 font-medium">
                        已燃烧EGOLD
                        </td>
                        <td className="tableitemx whitespace-nowrap px-6 py-4 float-right">
                          {parseFloat(noround(totalburnt, 2)).toLocaleString()}
                        </td>
                      </tr>
                      <tr>
                        <td className="tableitemx whitespace-nowrap px-6 py-4 font-medium">
                        当前供应量
                        </td>
                        <td className="tableitemx whitespace-nowrap px-6 py-4 float-right">
                          {parseFloat(
                            noround(currentSupply, 2)
                          ).toLocaleString()}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
export default TotaleSupply;
