import React from "react";

function Loader(props) {
  return (
    <>
      <div
        className="loading-text"
        style={{ color: "#000", textAlign: "center" }}
      >
        {" "}
        <div className="loading"></div>
      </div>
    </>
  );
}
export default Loader;
