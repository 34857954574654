import React from "react";

export default function Contact() {
  return (
    <div className="container mx-auto  mb-16 pb-5 mt-[100px] md:mt-[50px] lg:mt-10 px-10 lg:pb-[30px] bg-[#dfe7ea] rounded-lg">
      <h1 className="uppercase text-center font-bold pt-8 pb-10 text-base text-[#585858] ">
      联系方式
      </h1>
      <p className="text-[15px] text-[#585858] leading-8 text-center">
      如需进一步查询有关事宜，请联系我们
{" "}
        <a href="mailto:contact@egold.farm" style={{ color: "black" }}>
          contact@egold.farm
        </a>
      </p>
      <br />
    </div>
  );
}
