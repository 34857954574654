import React from "react";

export default function StayActive() {
  return (
    <div className="main-container w-[100%] text-[0px] relative mx-auto my-0 max-w-[900px]">
      <span className="heads sthead flex justify-center items-start font-['Poppins'] text-[40px] font-bold leading-[60px] text-[#777777] relative text-center whitespace-nowrap mt-0 mb-0 mx-auto">
      保持活跃
      </span>
      <div className="relative z-[24] mt-[50px] mr-0 mb-0 ml-0">
        <div className="stflboxes flex justify-between main-container relative mx-auto my-0">
          <div className="flex flex-col items-center">
            <div className="w-[90px] h-[90px] bgtop buyegoldbg bg-[length:100%_100%] bg-no-repeat relative z-[6] mt-0 mr-0 mb-0 " />
            <span className="flex w-[129px] h-[42px] justify-center items-start font-['Poppins'] text-[28px] font-bold leading-[42px] text-[#000]  relative text-center whitespace-nowrap z-[1] mr-0 mb-0 ">
            购买Egold
            </span>
            <span className="flex w-[250px] h-[36px] justify-center items-start font-['Poppins'] text-[12px] font-normal leading-[18px] text-[#000]  relative text-center overflow-hidden z-[2] mt-[9px] mr-0 mb-0 ">
            现在，您可以通过Egold平台购买、出售和持有$EGOLD。
            </span>
            <a href="/#login">
              <div className="w-[101px] h-[35px] stacbtn bg-cover bg-no-repeat rounded-[17px] relative z-[4] mt-[23px] mr-0 mb-0">
                <span className="flex w-[61px] justify-center items-start font-['Poppins'] text-[12px] font-bold leading-[18px] text-[#000] absolute top-[9px] left-[calc(50%-30.5px)] text-center whitespace-nowrap z-[5]">
                购买Egold
                </span>
              </div>
            </a>
            <div className="w-[300px] h-[325px] stacbg bg-cover bg-no-repeat rounded-[36px] absolute " />
          </div>
          <div className="flex flex-col items-center stflbox">
            <div className="w-[90px] h-[90px] bgtop stakeegoldbg bg-[length:100%_100%] bg-no-repeat relative z-[6] mt-0 mr-0 mb-0 " />
            <span className="flex w-[129px] h-[42px] justify-center items-start font-['Poppins'] text-[28px] font-bold leading-[42px] text-[#000]  relative text-center whitespace-nowrap z-[1] mr-0 mb-0 ">
            质押Egold
            </span>
            <span className="flex w-[250px] h-[36px] justify-center items-start font-['Poppins'] text-[12px] font-normal leading-[18px] text-[#000]  relative text-center overflow-hidden z-[2] mt-[9px] mr-0 mb-0 ">
            现在，您可以在平台上质押$EGOLD赚取超值17.68%的APR。
            </span>
            <a href="/#login">
              <div className="w-[101px] h-[35px] stacbtn bg-cover bg-no-repeat rounded-[17px] relative z-[4] mt-[23px] mr-0 mb-0">
                <span className="flex w-[61px] h-[18px] justify-center items-start font-['Poppins'] text-[12px] font-bold leading-[18px] text-[#000] absolute top-[9px] left-[calc(50%-30.5px)] text-center whitespace-nowrap z-[5]">
                质押Egold
                </span>
              </div>
            </a>
            <div className="w-[300px] h-[325px] stacbg bg-cover bg-no-repeat rounded-[36px] absolute " />
          </div>
          <div className="flex flex-col items-center stflbox">
            <div className="w-[90px] h-[90px] bgtop mineegoldbg bg-[length:100%_100%] bg-no-repeat relative z-[6] mt-0 mr-0 mb-0 " />
            <span className="flex w-[129px] h-[42px] justify-center items-start font-['Poppins'] text-[28px] font-bold leading-[42px] text-[#000] relative text-center whitespace-nowrap z-[1] mr-0 mb-0 ">
            挖Egold
            </span>
            <span className="flex w-[250px] h-[36px] justify-center items-start font-['Poppins'] text-[12px] font-normal leading-[18px] text-[#000]  relative text-center overflow-hidden z-[2] mt-[9px] mr-0 mb-0 ">
            现在，您可以购买任意不同功率的矿工。
            </span>
            <a href="/#login">
              <div className="w-[101px] h-[35px] stacbtn bg-cover bg-no-repeat rounded-[17px] relative z-[4] mt-[23px] mr-0 mb-0">
                <span className="flex w-[61px] h-[18px] justify-center items-start font-['Poppins'] text-[12px] font-bold leading-[18px] text-[#000] absolute top-[9px] left-[calc(50%-30.5px)] text-center whitespace-nowrap z-[5]">
                挖 Egold
                </span>
              </div>
            </a>
            <div className="w-[300px] h-[325px] stacbg bg-cover bg-no-repeat rounded-[36px] absolute " />
          </div>
        </div>
      </div>
    </div>
  );
}
