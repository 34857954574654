import React, { useEffect, useState } from "react";
import { ResponsivePie } from "@nivo/pie";
import Loader from "../Loader";

const TotaleSupply = () => {
  const [data, setData] = useState([]);

  const CenteredMetric = ({ centerX, centerY }) => {
    return (
      <text
        x={centerX}
        y={centerY}
        textAnchor="middle"
        dominantBaseline="central"
        fill="black"
        style={{
          fontSize: "20px",
        }}
      >
        820 K{" "}
      </text>
    );
  };

  useEffect(() => {
    setData([
      {
        id: "开发",
        label: "开发基金",
        value: noround(720000, 2),
        color: "#d94126",
      },
      {
        id: "团队",
        label: "团队基金",
        value: noround(77500, 2),
        color: "#e3e3e3",
      },
      {
        id: "开放流动性",
        label: "开放流动性",
        value: noround(22500, 2),
        color: "#e3e3e3",
      },
    ]);
  }, []);

  //to not round values while showing balance
  function noround(val, x) {
    if (!val) return 0;
    var float = parseFloat(val).toFixed(18);
    var num = float.toString();
    var n = num.slice(0, num.indexOf(".") + (x + 1));
    return n;
  }

  return (
    <>
      {data.length == 0 ? (
        <div className="cocont container mx-auto mt-4 mb-16 p-5 sm:mt-2 lg:mt-10 px-10 lg:p-[30px] bg-[#f8f4e9] rounded-lg">
          {" "}
          <Loader />
        </div>
      ) : (
        <div className="chartbx">
          <div className="chbxhdiv">
            <ResponsivePie
              data={data}
              margin={{ top: 40, right: 80, bottom: 80, left: 80 }}
              valueFormat=" >-,"
              startAngle={0}
              sortByValue={false}
              activeOuterRadiusOffset={8}
              arcLinkLabelsSkipAngle={10}
              arcLinkLabelsTextColor="#333333"
              arcLinkLabelsThickness={2}
              arcLinkLabelsColor={{ from: "color" }}
              arcLabelsSkipAngle={10}
              arcLabelsTextColor={{
                from: "color",
                modifiers: [["darker", 2]],
              }}
              layers={[
                "arcs",
                "arcLabels",
                "arcLinkLabels",
                "legends",
                CenteredMetric,
              ]}
              colors={{ scheme: "yellow_orange_brown" }}
              legends={[
                {
                  anchor: "bottom",
                  direction: "row",
                  justify: false,
                  translateX: 0,
                  translateY: window.innerWidth < 500 ? 60 : 56,
                  itemsSpacing: 0,
                  itemWidth: window.innerWidth < 500 ? 100 : 140,
                  itemHeight: 18,
                  itemTextColor: "#999",
                  itemDirection:
                    window.innerWidth < 500 ? "top-to-bottom" : "left-to-right",
                  itemOpacity: 1,
                  symbolSize: 18,
                  symbolShape: "circle",
                  effects: [
                    {
                      on: "hover",
                      style: {
                        itemTextColor: "#000",
                      },
                    },
                  ],
                },
              ]}
            />
          </div>
          <div className="flex flex-col chtable">
            <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
              <div className="inline-block min-w-full py-2 sm:px-6 lg:px-8">
                <div className="overflow-hidden">
                  <table className="min-w-full text-left text-sm font-light">
                    <tbody>
                      <tr className="border-b border-neutral-300">
                        <td className="tableitemx whitespace-nowrap px-6 py-4 font-medium">
                        开发基金
                        </td>
                        <td className="tableitemx whitespace-nowrap px-6 py-4 float-right">
                          {parseFloat(noround(720000, 2)).toLocaleString()}
                        </td>
                      </tr>
                      <tr className="border-b border-neutral-300">
                        <td className="tableitemx whitespace-nowrap px-6 py-4 font-medium">
                        团队基金
                        </td>
                        <td className="tableitemx whitespace-nowrap px-6 py-4 float-right">
                          {parseFloat(noround(77500, 2)).toLocaleString()}
                        </td>
                      </tr>
                      <tr>
                        <td className="tableitemx whitespace-nowrap px-6 py-4 font-medium">
                        开放流动性
                        </td>
                        <td className="tableitemx whitespace-nowrap px-6 py-4 float-right">
                          {parseFloat(noround(22500, 2)).toLocaleString()}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
export default TotaleSupply;
