import React, { useState, useEffect } from "react";

import { Link } from "react-scroll";
import { IoMdArrowDropupCircle } from "react-icons/io";

export default function UpArrow() {
  const [showDiv, setShowDiv] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const position = window.pageYOffset;
      if (position > 100) {
        setShowDiv(true);
      } else {
        setShowDiv(false);
      }
    };

    window.addEventListener("scroll", handleScroll, { passive: true });

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div>
      {showDiv && (
        <div
          style={{
            position: "fixed",
            right: "3%",
            bottom: "20px",
            cursor: "pointer",
            zIndex: 100,
          }}
        >
          <Link
            to="tophead"
            spy={true}
            smooth={true}
            offset={-150}
            duration={150}
          >
            <IoMdArrowDropupCircle color="rgb(255 175 3)" size={50} />
          </Link>
        </div>
      )}
    </div>
  );
}
